.bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: $dark_gray;
    position: sticky;
    top: 0;
    z-index: 1000 !important;

    .PrivateTabIndicator-root-3 {
        display: none;
        height: 0;
    }

    .MuiTabs-root {
        max-height: 30px;
        height: 10vh;
        min-height: 25px;
        background-color: $teal_lighter;
        width: 100%;

        .MuiTabs-flexContainer {
            max-height: 30px;
            height: 10vh;
            min-height: 25px;
            
            .Mui-selected {
                background-color: $teal;
                color: $white;
            }

            .MuiButtonBase-root {
                max-height: 30px;
                height: 10vh;
                min-height: 25px;
                min-width: 50px;
            }
        }
    }

}

.MuiTab-root {
    font-size: 12px;
    text-transform: none !important;
}

.subbar {
    @extend .bar;
    top: 30px;
    .MuiTabs-root {
        background-color: $white;
    }

}

.subbar2 {
    @extend .bar;
    top: 30px;
    .MuiTabs-root {
        background-color: $teal_lighter2;
    }

}

.thirdbar {
    @extend .bar;
    top: 60px;
    .MuiTabs-root {
        background-color: $white;
    }

}
.onTop {
    @extend .bar;
    top: 30px;
    .MuiTabs-root {
        background-color: $white;
        .MuiTabs-flexContainer {
            .Mui-selected {
                background-color: $white;
                color: $black;
            }
        }
    }
}

.onTop2 {
    @extend .onTop;
    top: 0px;
}

.active_link {
    color: $white;
}