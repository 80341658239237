.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1300;
    position: absolute;
    top: 45;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

.marker {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.refinery {
    background-image: url('../svg/refinery.svg');
}