.expanded {
    animation-name: toExpanded;
    animation-duration: 1s;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
  
.collapsed {
    color: cornflowerblue;
    animation-name: toCollapsed;
    animation-duration: 1s;
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
}


@keyframes toExpanded {
    from {
      color: cornflowerblue;
      -ms-transform: rotate(0deg); /* IE 9 */
      -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
      transform: rotate(0deg);
    }
    to {
      color: black;
      -ms-transform: rotate(180deg); /* IE 9 */
      -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
      transform: rotate(180deg);
    }
  }
  
  @keyframes toCollapsed {
    from {
      color: black;
      -ms-transform: rotate(180deg); /* IE 9 */
      -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
      transform: rotate(180deg);
    }
    to {
      color: cornflowerblue;
      -ms-transform: rotate(0deg); /* IE 9 */
      -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
      transform: rotate(0deg);
    }
}

.ag-center {
  .ag-header-group-cell {
      justify-content: center !important;
  }    
  .ag-header-cell-label {
      // text-overflow: unset;
      overflow: visible;
      white-space: normal;
      justify-content: center;
  }
}
.ag-packed {
    .ag-header-group-cell {
        justify-content: center !important;
    }    
    .ag-header-cell-label {
        // text-overflow: unset;
        overflow: visible;
        white-space: normal;
        justify-content: center;
    }
    .ag-cell {
        padding-left: 2px;
        padding-right: 2px;
        font-size: 12px !important;
        text-wrap: nowrap;
    }
    .ag-header-cell {
        padding-left: 2px;
        padding-right: 2px;
    }
    .ag-header-group-cell {
        padding-left: 2px;
        padding-right: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ag-packed-right {
    .ag-header-group-cell {
        justify-content: right !important;
    }
    .ag-header-cell-label {
        overflow: visible;
        white-space: normal;
        justify-content: right;
    }
    .ag-cell {
        padding-left: 2px;
        padding-right: 2px;
        font-size: 12px !important;
        text-wrap: nowrap;
    }
    .ag-header-cell {
        padding-left: 2px;
        padding-right: 2px;
    }
    .ag-header-group-cell {
        padding-left: 2px;
        padding-right: 2px;
        display: flex;
        align-items: center;
        justify-content: right;
    }
}
.ag-header-left {
    .ag-header-group-cell {
        justify-content: left !important;
    }
    .ag-header-cell-label {
        justify-content: left !important;
    }
    .ag-header-group-cell {
        justify-content: left !important;
    }
}
.ag-header-right {
    .ag-header-group-cell {
        justify-content: right !important;
    }
    .ag-header-cell-label {
        justify-content: right !important;
    }
    .ag-header-group-cell {
        justify-content: right !important;
    }
}
.ag-header-center {
    .ag-header-group-cell {
        justify-content: center !important;
    }
    .ag-header-cell-label {
        justify-content: center !important;
    }
    .ag-header-group-cell {
        justify-content: center !important;
    }
}

.white-space-normal .ag-header-cell-text {
    white-space: normal;
}