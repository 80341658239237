$amber2: #ffe082;
$green0: green;
$green1: #9cc400;
$green2: #00b050;
$green3: #338100;
$green4: #00632e;
$green_a1: #b9f6ca;
$aramco_blue: #55a7d2;
$dark_blue: #33478e;
$dark_gray: #3d3933;
$blue: blue;
$gray: #747065;
$light_gray: #ede9dc;
$white:#ffffff;
$teal: #58b09d;
$teal_light: #92c9b9;
$teal_lighter: #cadfd2;
$teal_lighter2: #e0f2f1;
$purple: #6d3170;
$purple_light: #a57297;
$purple_lighter: #d2bcc5;
$yellow: #f6cf35;
$yellow_light: #f8df6a;
$yellow_lighter: #fbedab;
$red_darker: #b71c1c;
$red_dark: #d32f2f;
$red: #e5633f;
$red0: red;
$red3: #e57373;
$red_light: #e99771;
$red_lighter: #f5c1b2;
$green: #c5e1a5;
$light_green: #D9E8CB;
$lighter_green: #EDF4E7;
$silver: #e9ecef;
$black: #000000;
$grey: grey;
$light_grey: #f5f5f5;
$blue: #0000EE;
$twitter_blue: #00acee;
$blue_gray: #3D4647;
$blue_gray_darker: #394243;
$orange0: orange;

.amber2 { color: $amber2; }
.blue_gray {color: $blue_gray; }
.green0 { color: $green0; }
.green1 { color: $green1; }
.green2 { color: $green2; }
.green3 { color: $green3; }
.green4 { color: $green4; }
.green_a1 { color: $green_a1; }
.aramco_blue { color: $aramco_blue; }
.dark_blue { color: $dark_blue; }
.blue { color: $blue; }
.dark_gray { color: $dark_gray; }
.gray { color: $gray; }
.light_gray { color: $light_gray; }
.white { color: $white !important; }
.teal { color: $teal; }
.teal_light { color: $teal_light !important; }
.teal_lighter { color: $teal_lighter !important; }
.yellow { color: $yellow; }
.yellow_light { color: $yellow_light; }
.yellow_lighter { color: $yellow_lighter; }
.red_darker { color: $red_darker; }
.red_dark { color: $red_dark; }
.red { color: $red; }
.red0 { color: $red0; }
.red3 { color: $red3; }
.red_light { color: $red_light; }
.red_lighter { color: $red_lighter; }
.green { color: $green; }
.light_green { color: $light_green; }
.lighter_green { color: $lighter_green; }
.silver { color: $silver; }
.black { color: $black; }
.grey { color: $grey; }
.blue { color: $blue; }
.twitter_blue { color: $twitter_blue; }
.purple { color: $purple; }
.purple_light { color: $purple_light; }
.purple_lighter { color: $purple_lighter; }
.orange0 { color: $orange0; }

.bg-amber2 { background-color: $amber2; }
.bg-blue_gray { background-color: $blue_gray; } 
.bg-blue_gray_darker { background-color: $blue_gray_darker; }
.bg-green0 { background-color: $green0; }
.bg-green1 { background-color: $green1 !important; }
.bg-green2 { background-color: $green2 !important; }
.bg-green3 { background-color: $green3; }
.bg-green4 { background-color: $green4; }
.bg-green_a1 { background-color: $green_a1; }
.bg-aramco_blue { background-color: $aramco_blue !important; }
.bg-dark_blue { background-color: $dark_blue !important; }
.bg-dark_gray { background-color: $dark_gray; }
.bg-gray { background-color: $gray; }
.bg-light_gray { background-color: $light_gray; }
.bg-white { background-color: $white; }
.bg-teal { background-color: $teal; }
.bg-teal_light { background-color: $teal_light !important; }
.bg-teal_lighter { background-color: $teal_lighter !important; }
.bg-yellow { background-color: $yellow; }
.bg-yellow_light { background-color: $yellow_light; }
.bg-yellow_lighter { background-color: $yellow_lighter; }
.bg-red { background-color: $red; }
.bg-red0 { background-color: $red0; }
.bg-red3 { background-color: $red3; }
.bg-red_light { background-color: $red_light; }
.bg-red_lighter { background-color: $red_lighter; }
.bg-red_dark { background-color: $red_dark; }
.bg-red_darker { background-color: $red_darker; }
.bg-green { background-color: $green; }
.bg-light_green { background-color: $light_green; }
.bg-lighter_green { background-color: $lighter_green; }
.bg-silver { background-color: $silver; }
.bg-black { background-color: $black; }
.bg-grey { background-color: $grey; }
.bg-blue { background-color: $blue; }
.bg-twitter_blue { background-color: $twitter_blue; }
.bg-purple { background-color: $purple; }
.bg-purple_light { background-color: $purple_light; }
.bg-purple_lighter { background-color: $purple_lighter; }
.bg-orange0 { background-color: $orange0; }

$hc-a: #2caffe;
$hc-b: #544fc5;
$hc-c: #00e272;
$hc-d: #fe6a35;
$hc-e: #6b8abc;
$hc-f: #d568fb;
$hc-g: #2ee0ca;
$hc-h: #fa4b42;
$hc-i: #feb56a;
$hc-j: #91e8e1;

.bg-hc-a { background-color: $hc-a; }
.bg-hc-b { background-color: $hc-b; }
.bg-hc-c { background-color: $hc-c; }
.bg-hc-d { background-color: $hc-d; }
.bg-hc-e { background-color: $hc-e; }
.bg-hc-f { background-color: $hc-f; }
.bg-hc-g { background-color: $hc-g; }
.bg-hc-h { background-color: $hc-h; }
.bg-hc-i { background-color: $hc-i; }
.bg-hc-j { background-color: $hc-j; }

.heatmap-0 { background-color: #BF360C; }
.heatmap-1 { background-color: #E64A19; }
.heatmap-2 { background-color: #FF5722; }
.heatmap-3 { background-color: #FF8A65; }
.heatmap-4 { background-color: #FFCCBC; }
.heatmap-5 { background-color: #FFFDE7; }
.heatmap-6 { background-color: #DCEDC8; }
.heatmap-7 { background-color: #AED581; }
.heatmap-8 { background-color: #8BC34A; }
.heatmap-9 { background-color: #689F38; }
.heatmap-10 { background-color: #33691E; }

$line_green: #00843D;
$deep_green: #BFE0CE;
$mid_green: #D9EDE2;
$light_green: #F3F7F5;
$nuetral: #000000;
$light_red: #FEF7F5;
$mid_red: #FDE7E3;
$deep_red: #FBD7CF;
$line_red: #F05F41;

.bg-deep_green { background-color: $deep_green; }
.bg-mid_green { background-color: $mid_green; }
.bg-light_green { background-color: $light_green; }
.bg-neutral { background-color: $nuetral; }
.bg-light_red { background-color: $light_red; }
.bg-mid_red { background-color: $mid_red; }
.bg-deep_red { background-color: $deep_red; }

.line_green { color: $line_green; }
.deep_green { color: $deep_green; }
.mid_green { color: $mid_green; }
.light_green { color: $light_green; }
.neutral { color: $nuetral; }
.light_red { color: $light_red; }
.mid_red { color: $mid_red; }
.deep_red { color: $deep_red; }
.line_red { color: $line_red; }
