.manage {
    &__avatar {
        &__checked {
            background-color: $green3 !important;
        }
        &__tab {
            background-color: $aramco_blue !important;
            margin-right: 10px;
        }
    }
}