* { box-sizing: border-box; }
$duration: 120s;

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.ticker-wrap {
    position: fixed;
    bottom: 49px;
    width: 100%;
    overflow: hidden;
    height: 28px;
    padding-left: 100%;
    box-sizing: content-box;

    .ticker {
        display: inline-block;
        height: 28px;
        line-height: 28px;  
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;
        -webkit-animation-iteration-count: infinite; 
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: $duration;
        animation-duration: $duration;
    }
}

.inline_block {
    display: inline-block;
}