.login {
    display: flex;
    align-items: center;
    justify-content: center;
    &__card {
        width: 300px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        &__action {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            &__button {
                color: $light_gray;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }

}
